
import { useUserStore } from '@/stores/user';


export default defineNuxtRouteMiddleware((to, from) => {
    const userStore = useUserStore();
    const token = userStore.getToken;

    if (!token) {

        return navigateTo({
            path: '/login',
            query: {
                ...to.query,
                redirect: to.fullPath
            },
        });
    }
});
